<template>
  <div class="page1">
    <!-- PDA-调整 -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          :rules="rules"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="道路">
                <auto-complete
                  ref="select"
                  @dataChange="(v) => (temParkData = v)"
                  slaveRelations="0,1,2"
                  @valueChange="completeValue"
                  :operationId="formInline.operationId"
                ></auto-complete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  placeholder="请输入泊位号"
                  maxlength="30"
                  v-model="formInline.berthCode"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Operator')">
                <el-input
                  v-model="formInline.pdaManagerName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Operation_time')">
                <timeRangePick
                  v-if="isviftime"
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defaultRange="defaultRangeValue"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.The_interval_minutes')" prop="intervalTime">
                <el-input
                  v-model="formInline.intervalTime"
                  placeholder="请输入内容"
                  maxlength="8"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  searchData();
                  page = 1;
                "
                :loading="loading"
                >查询
              </el-button>
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="
              $route.meta.authority.button.export ||
              ($route.meta.authority.button.export && false)
            "
          ></div>
          <div
            class="col_box h44"
            v-if="
              $route.meta.authority.button.export ||
              ($route.meta.authority.button.export && false)
            "
          >
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                :exportData="exportData"
                v-if="$route.meta.authority.button.export && false"
              ></exportFile>
              <el-button
                v-if="$route.meta.authority.button.export"
                type="info"
                icon="el-icon-upload2"
                @click="exportFileBtn"
              >
                导出
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// exportExcelNew
import { exportExcelNew, formatDuring } from "@/common/js/public";
import timeRangePick from "@/components/timePicker";
import autoComplete from "@/components/autocomplete";
import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "entryExitOperationPDA",
  data() {
    return {
      rules: {
        // 间隔分钟
        intervalTime: [
          { required: false, message: "请输入分钟数", trigger: "change" },
          { pattern: /^\+?[0-9]\d*$/, message: "请输入大于等于0的正整数" },
        ],
      },
      defalutDate: [],
      temParkData: {},
      roleList: [],
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
        },
        {
          prop: "pdaManagerName",
          label: this.$t("list.Operator"),
          width: "100",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row) => {
            if (/无/g.test(row.plateNumber)) {
              return this.$t("list.Unlicensed_car");
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "typeName",
          label: this.$t("list.Entry_and_exit_types"),
          width: "100",
        },
        {
          prop: "entryOrExitTime",
          label: this.$t("list.Entry_and_exit_time"),
          width: "180",
        },
        {
          prop: "recordTime",
          label: this.$t("list.Operation_time"),
          width: "180",
        },
        {
          prop: "intervalTime",
          label: this.$t("list.Interval_duration"),
          width: "100",
          formatter: (row) => {
            if (row.intervalTime) {
              return formatDuring(row.intervalTime * 60);
            } else {
              return "0分钟";
            }
          },
          // formatter: (row) => {
          //   return formatDuring(row.intervalTime * 60);
          // },
        },
      ],
      tableData: [],
      formInline: {
        berthCode: "",
        parkId: "",
        operationId: "",
        pdaManagerName: "",
        intervalTime: "",
        recordStartTime: "",
        recordEndTime: "",
      },
      exportData: {},
      isviftime: true,
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.pdaManagerName) {
        this.defalutDate = [
          this.$route.params.startTime,
          this.$route.params.endTime,
        ];
        this.formInline.pdaManagerName = this.$route.params.pdaManagerName;
        this.formInline.operationId = this.$route.params.operationId;
        this.formInline.type = this.$route.params.type;
        this.isviftime = false;
        setTimeout(() => {
          this.timeChange([
            this.$route.params.startTime,
            this.$route.params.endTime,
          ]);
          this.isviftime = true;
          this.searchData();
        }, 300);
      }
    },
  },
  computed: {
    defaultRangeValue() {
      return [
        {
          typeNumber: this.formInline.berthCode,
          warntip: "请输入您查询的泊位号",
        },
      ];
    },
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr, shortcutKey) {
      this.formInline.recordStartTime = timeArr[0];
      this.formInline.recordEndTime = timeArr[1];
      if (shortcutKey) {
        this.searchData();
      }
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    indexMethod(index) {
      return index + 1;
    },
    completeValue(item) {
      this.formInline.parkId = item;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    showLog() {
      if (this.formInline.recordStartTime && this.formInline.recordEndTime) {
        // let time =
        //   new Date(this.formInline.recordEndTime) - new Date(this.formInline.recordStartTime);
        // time = time / (24 * 60 * 60 * 1000);
        // if (!this.formInline.berthCode && time >= 31) {
        //   this.$alert("查询时间不能大于31天");
        //   return false;
        // } else {
        return true;
        // }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    exportFileBtn() {
      // /attend/attendance/export
      let opt = {
        pageNum: this.page,
        pageSize: this.pageSize,
        ...this.formInline,
      };
      exportExcelNew("/acb/2.0/pdaRecord/export", opt);
    },
    // 搜索
    searchData() {
      // this.tableData = [];
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.$refs.select.setShowVal(this.temParkData.parkName);
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      if (!this.showLog()) {
        return;
      }
      this.$axios
        .get("/acb/2.0/pdaRecord/list", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {
    // InfoList,
    // picDetail,
    autoComplete,
    exportFile,
    timeRangePick,
  },
  created() {
    if (this.$route.params.pdaManagerName) {
      this.defalutDate = [
        this.$route.params.startTime,
        this.$route.params.endTime,
      ];
      this.formInline.pdaManagerName = this.$route.params.pdaManagerName;
      this.formInline.operationId = this.$route.params.operationId;
      this.formInline.type = this.$route.params.type;
    } else {
      this.defalutDate = [
        this.dataTimeRest() + " 00:00:00",
        this.dataTimeRest() + " 23:59:59",
      ];
    }
  },
  mounted() {
    // this.formInline = {
    //   parkId: '',
    //   operationId: '',
    //   pdaManagerName: '',
    //   intervalTime: '',
    //   recordStartTime: this.formInline.recordStartTime,
    //   recordEndTime: this.formInline.recordEndTime
    // }
    // 导出数据
    this.exportData = {
      url: "/acb/2.0/pdaRecord/export",
      methods: "post",
      data: {
        name: "PDA入出场操作记录",
        pageNum: this.page,
        pageSize: this.pageSize,
        ...this.formInline,
      },
    };
    this.getRoleList();
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.conborder {
  border-top: 1px solid #C0CCDA;
  overflow: hidden;
  padding: 10px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding 22px 22px 0pxs
    // margin-bottom 20px
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
<style scoped>
.parkTitle {
  border-bottom: 1px solid #e1e1e1;
}

.parkTitle span {
  text-align: center;
  display: inline-block;
  width: 100px;
  line-height: 30px;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
}

.pic {
  margin-top: 20px;
}

.pic img {
  width: 200px;
  height: 200px;
}

.picTitle {
  line-height: 30px;
}

.detail1 {
  line-height: 25px;
}
</style>
